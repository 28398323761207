import React, { ReactNode } from 'react';
import { ScoreRoundData } from "../../../toolympus/components/Contests/Grid/Parties/useScoreRoundByCode";
import { Arbiter, Round } from "../../../toolympus/components/Contests/Grid/types";
import { MoreActionsMenuButton, Tooltip } from "../../../toolympus/components/primitives";
import { RolesKeys } from "../../Auth";
import { RoundInfo } from "../useRoundInfoEdit";
import { StatusChip } from "./ArbitratorDisplay";
import { RoundScoringStatusData, StageScoringStatusData } from "./useScoringStatus";


export const RoundStatuses = {
  notStarted: "not-started",
  checkins: "checkins",
  inProgress: "in-progress",
  scoring: "scoring",
  finished: "finished",
}

export const canProvideTiming = (status: string | null | undefined) => {
  return !status
    || status === RoundStatuses.notStarted
    || status === RoundStatuses.checkins;
}

export const canCheckin = (status: string | null | undefined) => {
  return !!status && status !== RoundStatuses.notStarted;
}


const RoundStatusNames: Record<string, string> = {
  "not-started": "Not started",
  "checkins": "Check-ins",
  "in-progress": "In progress",
  "scoring": "Scoring",
  "finished": "Finished",
}

export const RoundStatus = {
  "not-started": { status: "unknown", label: "Not started", description: "Please wait for bailiff to start the round" },
  "checkins": { status: "warning", label: "Checking in", description: "Awaiting participants to check in" },
  "in_progress": { status: "warning", label: "In progress", description: "Round is in progress" },
  
  "team_in_progress": { status: "good", label: "In progress", description: "Round is in progress" },
  "arbitrator_scoring": { status: "warning", label: "In progress", description: "Round is in progress" },
  "scoring": { status: "warning", label: "Scoring", description: "Judges are setting scores" },
  
  "finished": { status: "good", label: "Finished", description: "Round is finished" },
}

export type RoundStatusT = keyof typeof RoundStatus;


interface ParticipantRoundStatusProps {
  roundInfo: RoundInfo;
  arbitrators: (Arbiter | null)[];
  role: keyof typeof RolesKeys;
  everyoneCheckedIn: boolean;
  scoringStatus: RoundScoringStatusData | undefined;
  scoreRound: ScoreRoundData | undefined;
}

export const getParticipantRoundStatus = (p: ParticipantRoundStatusProps): RoundStatusT  => {
  const { roundInfo, arbitrators, role, scoringStatus, scoreRound } = p;

  const { round_status } = roundInfo;

  if(!round_status || round_status === RoundStatuses.notStarted) {
    return "not-started";
  }

  if(round_status === RoundStatuses.checkins) {
    return "checkins";
  }

  if(round_status === RoundStatuses.finished) {
    return "finished";
  }


  if(role === "team") {
    return "team_in_progress";
  }

  if(role === "arbitrator") {
    if (scoreRound && scoreRound.scoresGrouped.every(sg => sg.left?.is_scored && sg.right?.is_scored)) {
      return "finished";
    } else {
      return "arbitrator_scoring";
    }
  }

  if (scoringStatus) {
    const scoringStatuses = arbitrators.filter(a => !!a).map(a => scoringStatus?.getStatus(a?._id || ""));
    if (scoringStatuses.every(s => s?.is_fully_scored)) {
      return "finished";
    } else if (scoringStatuses.some(s => (s?.scored_cnt || 0) > 0)) {
      return "scoring";
    }
  }

  return "in_progress";
}


interface AdminRoundStatusProps {
  round: Round;
  roundInfo: RoundInfo | undefined;
  scoringStatus: StageScoringStatusData | undefined;
}

export const getAdminRoundStatus = (p: AdminRoundStatusProps): RoundStatusT  => {
  const { round, roundInfo, scoringStatus } = p;
  const { round_status } = roundInfo || {};
  
  const arbitrators = [1,2,3].map(position => round?.arbiters ? (round.arbiters[position] || null) : null);
  // const arbsCheckedIn = arbitrators.every(a => (roundInfo?.checkins || {})[a?._id || ""]);
  // const teamsCheckedIn = Object.values(roundInfo?.teams_timing || {}).filter(x => !!x).length === 2;

  if(!round_status || round_status === RoundStatuses.notStarted) {
    return "not-started";
  }

  if(round_status === RoundStatuses.checkins) {
    return "checkins";
  }

  if(round_status === RoundStatuses.finished) {
    return "finished";
  }

  // if(!teamsCheckedIn || !arbsCheckedIn) {
  //   return "checkins";
  // }

  if (scoringStatus) {
    const scoringStatuses = arbitrators.filter(a => !!a).map(a => scoringStatus?.getStatus(round._id, a?._id || ""));
    if (scoringStatuses.every(s => s?.is_fully_scored)) {
      return "finished";
    } else if (scoringStatuses.some(s => (s?.scored_cnt || 0) > 0)) {
      return "scoring";
    }
  }

  return "in_progress";
}


export const RoundStatusChip = (props: { status: RoundStatusT }) => {
  const statusInfo = RoundStatus[props.status];
  return <Tooltip text={statusInfo.description}><StatusChip status={statusInfo.status as any} label={statusInfo.label} /></Tooltip>
}


export const RoundStatusChangeButton = (props: { roundInfo: RoundInfo, changeStatus: (status: string) => any }) => {
  const currentStatus = props.roundInfo.round_status || RoundStatuses.notStarted;

  const availableStatuses = [RoundStatuses.notStarted, RoundStatuses.checkins, RoundStatuses.inProgress, RoundStatuses.finished].filter(x => x !== currentStatus);

  return (
    <MoreActionsMenuButton
      actions={[
        ...availableStatuses.map(s => ([
          s, `-> ${RoundStatusNames[s]}`, () => props.changeStatus(s), {},
        ] as [string, ReactNode, () => any, any]))
      ]}
      />
  )
}