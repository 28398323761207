import React from 'react';
import { useTestRoundData } from './useParticipantRoundData';
import { ParticipantRoundPage } from './ParticipantRoundPage';

interface Props {
  
}

export const TestRoundPage = (props: Props) => {
  const data = useTestRoundData();
  return (
    <ParticipantRoundPage data={data} isArbitrator testCourtRoom />
  );
}
