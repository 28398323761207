import React from 'react';
import { useCourtRoom, useTestCourtRoom } from './useCourtRoom';
import { CourtRoomUnbound } from './CourtRoom';
import { DisplayNameSuffix } from './CourtRoomPage';
import { useUser } from '../../../../toolympus/userContext/UserContext';
import { getUserPrimaryRole } from '../RoundPagesComponents';
import { RolesKeys } from '../../../Auth';

interface Props {
  stage?: string;
  roundId?: string;
  kind?: "round" | "deliberations";
  domain: string;
  name: string;
  onDisconnect?: () => void;
  wrapEditorWithPanel?: boolean;
}

export const CourtRoomPanel = (props: Props) => {
  const { stage, roundId, kind, domain, name } = props;
  const data = useCourtRoom(stage, roundId);

  const { user } = useUser();
  const primaryRole = getUserPrimaryRole(user);
  
  return (
    <CourtRoomUnbound
      kind={(kind as "round" | "deliberations") || "round"}
      domain={domain}
      stage={stage}
      roundId={roundId}
      data={data}
      userDisplayName={name}
      displayNameSuffix={DisplayNameSuffix[primaryRole]}
      editName={primaryRole === RolesKeys.team || primaryRole === RolesKeys.admin}
      onDisconnect={props.onDisconnect}
      wrapEditorWithPanel={props.wrapEditorWithPanel}
      />
  );
}


export const TestCourtRoomPanel = (props: Props) => {
  const { stage, roundId, kind, domain, name } = props;
  const data = useTestCourtRoom(stage, roundId);

  const { user } = useUser();
  const primaryRole = getUserPrimaryRole(user);
  
  return (
    <CourtRoomUnbound
      kind={(kind as "round" | "deliberations") || "round"}
      domain={domain}
      stage={stage}
      roundId={roundId}
      data={data}
      userDisplayName={name}
      displayNameSuffix={DisplayNameSuffix[primaryRole]}
      editName={primaryRole === RolesKeys.team || primaryRole === RolesKeys.admin}
      onDisconnect={props.onDisconnect}
      wrapEditorWithPanel={props.wrapEditorWithPanel}
      />
  );
}
