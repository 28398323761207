import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Arbiter } from '../../../toolympus/components/Contests/Grid/types';
import { BaseBiIconProps } from '../../Common/Icons';
import { Buttons, LoadingIndicator, PseudoLink2, useCopyText } from '../../../toolympus/components/primitives';
import { Avatar } from '../../Common/Primitives';
import { Chip, Typography } from '@mui/material';
import { BiAt, BiCheck, BiHourglass, BiPhone } from 'react-icons/bi';
import { ThemeSettings } from '../../../theme';
import { Arbitrator } from '../../Arbitrators';
import { useUser } from '../../../toolympus/userContext/UserContext';
import { ArbitratorRoundScoringStatus, RoundScoringStatusData } from './useScoringStatus';

export const ContactIconProps = { ...BaseBiIconProps, style: { width: "1rem", height: "1rem" } };

export const IconPseudoLink = (props: { icon: ReactNode, content?: string }) => {
  const copyText = useCopyText();
  return props.content
    ? <Buttons>{props.icon} <PseudoLink2 onClick={() => copyText(props.content || "")}>{props.content}</PseudoLink2></Buttons>
    : null;
}

export const ContactsLineWrapper = styled(Buttons)<{ vertical?: boolean }>`
  & .${BaseBiIconProps.className} {
    color: ${ThemeSettings.colors.shadeLightHalfTransp};
  }

  flex-flow: ${props => props.vertical ? "column" : "row"};
  align-items: ${props => props.vertical ? "flex-start" : "center"};

  @media ${ThemeSettings.sizes.breakpoints.sm.down} {
    flex-flow: column;
    align-items: flex-start;
  }
`;

export const ContactsLine = (props: { email?: string, phone?: string, before?: ReactNode, after?: ReactNode, vertical?: boolean }) => {
  const { email, phone, before, after } = props;

  if(!email && !phone && !before && !after) {
    return null;
  }

  return (
    <ContactsLineWrapper vertical={props.vertical}>
      {before}

      <IconPseudoLink icon={<BiAt {...ContactIconProps} />} content={email} />
      <IconPseudoLink icon={<BiPhone {...ContactIconProps} />} content={phone} />

      {after}
    </ContactsLineWrapper>
  )
}

interface ArbitratorStatusProps {
  isCheckedIn?: boolean;
  isSelf?: boolean;
  checkin?: () => void;
  isLoading?: boolean;
}

export const StatusChip = styled(Chip)<{ status?: keyof typeof ThemeSettings.colors.status, marginVertical?: boolean, marginHorizontal?: boolean }>`
  background-color: ${props => ThemeSettings.colors.status[props.status || "warning"]};
  margin: ${props => props.marginVertical ? 0.25 : 0}rem ${props => props.marginHorizontal ? 0.25 : 0}rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  cursor: pointer;
  & .${BaseBiIconProps.className} {
    width: 1rem !important;
    size: 1rem !important;
  }
`;
StatusChip.defaultProps = { size: "small" };

export const StatusChipCheckIcon = () => <BiCheck color="inherit" {...BaseBiIconProps} />;
export const StatusChipWaitingIcon = () => <BiHourglass color="inherit" {...BaseBiIconProps} />;

const ArbitratorStatus = (props: ArbitratorStatusProps) => {
  if(props.isCheckedIn) {
    return <StatusChip marginVertical status="good" label="Checked-in" icon={<StatusChipCheckIcon />} />
  } else if(!props.isSelf) {
    return <StatusChip marginVertical label="Waiting..." icon={<StatusChipWaitingIcon />} />
  } else if(props.isSelf) {
    return (
      <StatusChip
        marginVertical
        label="Please check-in"
        icon={props.isLoading ? <LoadingIndicator color="inherit" sizeVariant="s" /> : <StatusChipWaitingIcon />}
        onClick={props.checkin}
        />)
  } else {
    return <></>;
  }
}

const ArbitratorScoringStatus = (props: { status: ArbitratorRoundScoringStatus | undefined, scoresRequired: number }) => {
  const scoresSet = props.status?.scored_cnt || 0;

  return (
    <StatusChip
      marginVertical
      status={scoresSet === 0
        ? "unknown"
        : props.status?.is_fully_scored
          ? "good"
          : "warning"}
      label={`Scored: ${scoresSet} of ${props.scoresRequired}`}
      />
  )

}


interface ArbitratorProps {
  arbitrator: Arbiter | null;
  isCheckedIn?: boolean;
  position: number;
  isContactAvailable?: boolean;
  extraInfo?: Arbitrator;
  checkin?: () => void;
  isCheckinLoading?: boolean;
  scoringStatus?: RoundScoringStatusData;
}

export const ArbitratorDisplay = (props: ArbitratorProps) => {

  const { arbitrator, extraInfo, scoringStatus } = props;

  const user = useUser();

  if(!arbitrator) {
    return <div />
  }

  return (
    <Buttons>
      <Avatar src={extraInfo?.avatar_url} alt={(arbitrator.display_name || "")[0] || ""} />
      <div>
        <Typography style={{ fontWeight: 500 }}>{arbitrator.display_name}</Typography>
        {!(props.isCheckedIn && scoringStatus) &&
          <ArbitratorStatus
            isCheckedIn={props.isCheckedIn}
            isSelf={user.user?._id === arbitrator._id}
            checkin={props.checkin}
            isLoading={props.isCheckinLoading}
            />}
        {props.isCheckedIn && scoringStatus &&
          <ArbitratorScoringStatus
            scoresRequired={scoringStatus.scoresPerArbPerRound}
            status={scoringStatus.getStatus(arbitrator._id)}
            />}
        {props.isContactAvailable && <ContactsLine vertical email={extraInfo?.email} phone={extraInfo?.phone} />}
      </div>
    </Buttons>
  )
}
