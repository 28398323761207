import { useMemo } from "react";
import { Round } from "../../../toolympus/components/Contests/Grid/types";
import { LoadedData, useLoadedData } from "../../../toolympus/hooks/useLoadedData"
import { RoundInfo } from "../useRoundInfoEdit"
import { useAction } from "../../../toolympus/api/useAction";
import { useUser } from "../../../toolympus/userContext/UserContext";
import { apiFetch, downloadFile } from "../../../toolympus/api/core";
import { usePlatformPage } from "../../Common/data/usePlatformPage";
import { getCustomBlocksData } from "../../../toolympus/components/CMS/Usage";
import { useEditItem2 } from "../../../toolympus/api/useNewItem";
import { RoundToScore, ScoreRoundData } from "../../../toolympus/components/Contests/Grid/Parties/useScoreRoundByCode";
import { useScoreRound } from "./useScoreRound";
import { RoundScoringStatusData, useRoundScoringStatus } from "./useScoringStatus";
import { TimerData, useTimerStateBound } from "./Timer";
import { useRoundInfo } from "./useRoundInfoWithUpdates";
import { BailiffTeamTimingData, TeamsTimingData, useBailiffTeamTiming, useTeamsTiming } from "./useTeamsTiming";
import { canCheckin } from "./RoundStatus";



const usePageConfig = () => {
  const pageInfo = usePlatformPage("participant-round-page");
  const messages = useMemo(() => {
    const pageData = getCustomBlocksData((pageInfo.data.item.content as any)?.blocks);

    const bailiff = (pageData["bailiff_messages"] || {});
    const arbitrator = (pageData["arbitrator_messages"] || {});
    const team = (pageData["team_messages"] || {});
    const general = (pageData["general_messages"] || {});

    return {
      bailiff,
      arbitrator,
      team,
      general,
    };
  }, [pageInfo.data]);

  return {
    messages,
    isLoading: pageInfo.isLoading,
    state: pageInfo.data.contest_state,
    pageInfo,
  };
}

export type RoundPageConfig = ReturnType<typeof usePageConfig>;
export type RoundMessages = RoundPageConfig["messages"];

const useCheckin = (roundInfo: LoadedData<RoundInfo>) => {
  const { user } = useUser();

  const isCheckedIn = (roundInfo.data.checkins || {})[user?._id || ""];
  const checkIn = useAction(
    () => apiFetch<{}>(`/api/rounds-info/participant/${roundInfo.data.stage}/${roundInfo.data._id}/check-in`, "put", { checkin: true })
      .then(() => { roundInfo.reload(); return {}; }),
    !roundInfo.isLoading && !isCheckedIn);
  
  return {
    isLoading: roundInfo.isLoading || checkIn.isRunning,
    isCheckedIn,
    isRequireCheckIn: roundInfo.data._id && canCheckin(roundInfo.data.round_status) && !isCheckedIn,
    checkIn,
  }
}

type CheckInData = ReturnType<typeof useCheckin>;


const useHelpRequest = (roundInfo: LoadedData<RoundInfo>, author?: string) => {
  const isHelpRequested = !!roundInfo.data.help_requests?.length

  const request = useEditItem2<{ issue: string }>({
    save: (item, changes) => {
      const withAuthor = author ? `${author}: ${item.issue}` : item.issue;
      return apiFetch(`/api/rounds-info/participant/${roundInfo.data.stage}/${roundInfo.data._id}/help`, "post", { issue: withAuthor });
    },

  });

  return {
    isHelpRequested,
    ...request,
    save: (extraChanges?: Partial<{ issue: string }>) => request.save(extraChanges).then(x => { roundInfo.reload(); return x; }),
  }
}

type HelpRequestData = ReturnType<typeof useHelpRequest>;



export interface ParticipantRoundData {
  roundInfo: RoundInfo;
  round: Round;
  isLoading: boolean;
  checkin?: CheckInData;
  teamsTiming?: TeamsTimingData;
  teamsTimingBailiffEdit?: BailiffTeamTimingData;
  helpRequest?: HelpRequestData;
  config: RoundPageConfig;
  downloadMemorial: (fileId: string) => void;
  scoreRound?: ScoreRoundData;
  scoringStatus?: RoundScoringStatusData;
  timer: TimerData;
  changeRoundStatus?: (status: string) => Promise<RoundInfo>;
}

const downloadMemorial = (stage: string, roundId: string, fileId: string) => downloadFile(`/api/rounds-info/participant/${stage}/${roundId}/document/${fileId}`);

export const useParticipantRoundData = (stage: string, roundId: string): ParticipantRoundData => {
  const { roundInfo } = useRoundInfo(`/api/rounds-info/${stage}/${roundId}`, true);
  const round = useLoadedData<Round>(`/api/rounds/stage/${stage}/round/${roundId}`, { } as Round);

  const config = usePageConfig();
  const helpRequest = useHelpRequest(roundInfo);

  const timer = useTimerStateBound(roundInfo.data, round.data);

  return {
    roundInfo: roundInfo.data,
    round: round.data,
    isLoading: roundInfo.isLoading || round.isLoading,
    config,
    helpRequest,
    downloadMemorial: (fileId: string) => downloadMemorial(round.data.stage_code, round.data._id, fileId),
    timer,
  }
}

export const useArbitratorRoundData = (roundId: string): ParticipantRoundData => {
  const round = useLoadedData<Round>(`/api/rounds/arbiter/round/${roundId}`, { } as Round);
  const { roundInfo } = useRoundInfo(`/api/rounds-info/participant/${round.data.stage_code}/${roundId}`, !!round.data.stage_code);

  const { user } = useUser();
  const me = Object.values(round.data?.arbiters || {}).find(a => a._id === user?._id);

  const checkin = useCheckin(roundInfo);
  const helpRequest = useHelpRequest(roundInfo, me?.display_name);
  const config = usePageConfig();

  const scoreRound = useScoreRound(round as unknown as LoadedData<RoundToScore>);

  const timer = useTimerStateBound(roundInfo.data, round.data);

  return {
    roundInfo: roundInfo.data,
    round: round.data,
    isLoading: roundInfo.isLoading || round.isLoading,
    checkin,
    helpRequest,
    config,
    downloadMemorial: (fileId: string) => downloadMemorial(round.data.stage_code, round.data._id, fileId),
    scoreRound,
    timer,
  }
}

export const useTeamRoundData = (roundId: string): ParticipantRoundData => {
  const rounds = useLoadedData<Round[]>(`/api/rounds/player/round`, []);
  const round = useMemo(() => {
    return rounds.data.find(r => r._id === roundId) || {} as Round;
  }, [rounds.data, roundId])
  const { roundInfo } = useRoundInfo(`/api/rounds-info/participant/${round?.stage_code}/${roundId}`, !!round?.stage_code);

  const { user } = useUser();
  const me = Object.values(round.players || {}).find(t => t._id === user?._id);

  const helpRequest = useHelpRequest(roundInfo, me?.display_name);
  const config = usePageConfig();
  const teamsTiming = useTeamsTiming(roundInfo, round);

  const timer = useTimerStateBound(roundInfo.data, round);

  return {
    roundInfo: roundInfo.data,
    round,
    helpRequest,
    isLoading: roundInfo.isLoading || rounds.isLoading,
    teamsTiming,
    config,
    downloadMemorial: (fileId: string) => downloadMemorial(round.stage_code, round._id, fileId),
    timer,
  }
}

export const useBailiffRoundData = (roundId: string): ParticipantRoundData => {
  const round = useLoadedData<Round>(`/api/rounds/bailiff/round/${roundId}`, { } as Round);
  const { roundInfo } = useRoundInfo(`/api/rounds-info/participant/${round.data.stage_code}/${roundId}`, !!round.data.stage_code);

  const checkin = useCheckin(roundInfo);
  const helpRequest = useHelpRequest(roundInfo);

  const scoringStatus = useRoundScoringStatus(round.data.stage_code, roundId);
  const teamsTimingBailiffEdit = useBailiffTeamTiming(roundInfo, round.data);
  
  const config = usePageConfig();

  const timer = useTimerStateBound(roundInfo.data, round.data);

  
  const changeRoundStatus = (status: string) => {
    return apiFetch<RoundInfo>(`/api/rounds-info/participant/${round.data.stage_code}/${roundId}/round-status`, "put", { round_status: status });
  }


  return {
    roundInfo: roundInfo.data,
    round: round.data,
    isLoading: roundInfo.isLoading || round.isLoading,
    checkin,
    helpRequest,
    config,
    downloadMemorial: (fileId: string) => downloadMemorial(round.data.stage_code, round.data._id, fileId),
    scoringStatus,
    teamsTimingBailiffEdit,
    timer,
    changeRoundStatus,
  }
}

export const useAdminRoundData = (stage: string, roundId: string): ParticipantRoundData => {
  const { roundInfo } = useRoundInfo(`/api/rounds-info/${stage}/${roundId}`, !!stage && !!roundId);
  const round = useLoadedData<Round>(`/api/rounds/stage/${stage}/round/${roundId}`, { } as Round, !!stage && !!roundId);
  
  const helpRequest = useHelpRequest(roundInfo);

  const scoringStatus = useRoundScoringStatus(round.data.stage_code, roundId);
  const teamsTimingBailiffEdit = useBailiffTeamTiming(roundInfo, round.data);

  const config = usePageConfig();

  const timer = useTimerStateBound(roundInfo.data, round.data);

  const changeRoundStatus = (status: string) => {
    return apiFetch<RoundInfo>(`/api/rounds-info/participant/${stage}/${roundId}/round-status`, "put", { round_status: status });
  }

  return {
    roundInfo: roundInfo.data,
    round: round.data,
    helpRequest,
    isLoading: roundInfo.isLoading || round.isLoading,
    config,
    downloadMemorial: (fileId: string) => downloadMemorial(round.data.stage_code, round.data._id, fileId),
    scoringStatus,
    teamsTimingBailiffEdit,
    timer,
    changeRoundStatus,
  }
}


export const useTestRoundData = (): ParticipantRoundData => {
  const round: Round = {
    config_code: "",
    stage_code: "rating",
    _id: "00000000-0000-0000-0000-000000000000",
    arbiters: {
      1: { _id: "00000001-0000-0000-0000-000000000001", display_name: "Mrs. Judge 1"},
      2: { _id: "00000001-0000-0000-0000-000000000002", display_name: "Mr. Judge 2"},
      3: { _id: "00000001-0000-0000-0000-000000000003", display_name: "Mrs. Judge 3"},
    },
    players: {
      1: { _id: "00000002-0000-0000-0000-000000000001", display_name: "1111"},
      2: { _id: "00000002-0000-0000-0000-000000000002", display_name: "9999"},
    },
    info: {
      teams_documents: {
        1: { memorial_1_id: "00000002-0002-0000-0000-000000000012", memorial_2_id: "00000002-0002-0000-0000-000000000012" },
        2: { memorial_1_id: "00000002-0002-0000-0000-000000000012", memorial_2_id: "00000002-0002-0000-0000-000000000012" },
      },
    },
    title: "Test round",
    number: 9999,
    description: "",
    time: "2025-02-05 09:00:00",
  }
  const roundInfo: RoundInfo = {
    _id: round._id,
    stage: round.stage_code,
    bailiff_id: round._id,
    checkins: {
      [round.arbiters[1]._id]: true,
      [round.arbiters[2]._id]: true,
    },
    teams_timing: {
      [round.players[1]._id]: {
        speaker_1: { speaker_id: "00000002-0002-0000-0000-000000000011", speaker_name: "Ms Applicant Speaker", time: 20 },
        speaker_2: { speaker_id: "00000002-0002-0000-0000-000000000021", speaker_name: "Mr Applicant Speaker", time: 23 },
        rebuttal: { time: 2 },
      },
      [round.players[2]._id]: {
        speaker_1: { speaker_id: "00000002-0002-0000-0000-000000000012", speaker_name: "Ms Respondant Speaker", time: 22 },
        speaker_2: { speaker_id: "00000002-0002-0000-0000-000000000022", speaker_name: "Mr Respondant Speaker", time: 20 },
        rebuttal: { time: 3 },
      },
    },
    is_started: true,
  };

  // const checkin = useCheckin(roundInfo);
  // const helpRequest = useHelpRequest(roundInfo);

  // const scoringStatus = useRoundScoringStatus(round.data.stage_code, roundId);
  // const teamsTimingBailiffEdit = useBailiffTeamTiming(roundInfo, round.data);
  
  const config = usePageConfig();

  const timer = useTimerStateBound(roundInfo, round);


  return {
    roundInfo: roundInfo,
    round: round,
    isLoading: false,
    checkin: {
      checkIn: { run: () => Promise.resolve({}), canRun: true, isRunning: false, buttonProps: { disabled: false, onClick: () => ({}), startIcon: null } },
      isCheckedIn: true,
      isLoading: false,
      isRequireCheckIn: false,
    },
    helpRequest: {
      isHelpRequested: false,
      update: () => {},
      cancel: () => {},
      errors: {} as any,
      isEditing: false,
      isLoading: false,
      item: null,
      save: () => Promise.resolve({ issue: "" }),
      setItem: () => {},
      startEditing: () => {},


    },
    config,
    downloadMemorial: (fileId: string) => downloadMemorial(round.stage_code, round._id, fileId),
    scoringStatus: {
      isLoading: false,
      data: {},
      reload: () => {},
      setData: () => {},
      getStatus: () => ({ is_fully_scored: false, scored_cnt: 0, records: [] }),
      scoresPerArbPerRound: 4,
    },
    timer,
    scoreRound: {
      activeScore: null,
      cancelScoring: () => {},
      getPlayer: () => round.players[1],
      round: {
        data: {
          ...round,
          scores: [],
        },
        isLoading: false,
        reload: () => {},
        setData: () => {},
      },
      setPlayerSelectScore:  () => {},
      startScoring: () => {},
      scoring: {
        sheet: {
          code: "0",
          title: "Scoring sheet",
          criteria: [],
          description: "",
        },
        scores: [],
        canSubmit: false,
        submit: () => Promise.resolve(),
        update: () => {},
        isLoading: false,
        isSaving: false,
        isSubmitted: true,
        totalNumeric: 0,
      },

      scoresGrouped: [
        {
          group_key: "perf1",
          is_double: true,
          label: "X",
          left: {
            _id: "00000005-0001-0000-0000-000000000001",
            arbiter_id: round.arbiters[1]._id,
            is_scored: true,
            score_label: "P1",
            round_id: round._id,
            score_key: "perf1",
            score_type: "",
            position: 1,
          },
          right: {
            _id: "00000005-0001-0000-0000-000000000002",
            arbiter_id: round.arbiters[1]._id,
            is_scored: false,
            score_label: "P1",
            round_id: round._id,
            score_key: "perf1",
            score_type: "",
            position: 2,
          },
        },
        {
          group_key: "perf2",
          is_double: true,
          label: "X2",
          left: {
            _id: "00000005-0002-0000-0000-000000000001",
            arbiter_id: round.arbiters[1]._id,
            is_scored: false,
            score_label: "P2",
            round_id: round._id,
            score_key: "perf2",
            score_type: "",
            position: 1,
          },
          right: {
            _id: "00000005-0002-0000-0000-000000000002",
            arbiter_id: round.arbiters[1]._id,
            is_scored: false,
            score_label: "P2",
            round_id: round._id,
            score_key: "perf2",
            score_type: "",
            position: 2,
          },
        }
      ],
    }
  }
}
