import { useState } from 'react';
import { useLoadedData } from '../../../../toolympus/hooks/useLoadedData';
import { apiFetch } from '../../../../toolympus/api/core';


export const useCourtRoomLobby = (stage: string | undefined, roundId: string | undefined) => {
  const apiPath = `/api/rounds-info/participant/${stage}/${roundId}/court-room`;
  const [isStarted, setIsStarted] = useState<"round" | "deliberations" | null>(null);
  const state = useLoadedData<{ is_available: boolean, domain: string }>(`${apiPath}`, { is_available: false, domain: "" }, !!stage && !!roundId);

  const getCourtRoomUrl = (kind?: "round" | "deliberations"): Promise<string> => {
    return apiFetch<CourtRoomTokens>(`${apiPath}/tokens`)
      .then(tokens => `${state.data.domain}/${kind === "deliberations" ? tokens.room_name_deliberations : tokens.room_name_round}?jwt=${kind === "deliberations" ? tokens.token_deliberations : tokens.token_round}`);
  }

  return {
    ...state,
    isStarted,
    join: (kind?: "round" | "deliberations") => state.data.is_available ? setIsStarted(kind || "round") : null,
    leave: () => setIsStarted(null),
    getCourtRoomUrl,
  }
}

export const useTestCourtRoomLobby = () => {
  const apiPath = `/api/rounds-info/participant/test-round/court-room/tokens`;
  const [isStarted, setIsStarted] = useState<"round" | "deliberations" | null>(null);
  const state = useLoadedData<{ is_available: boolean, domain: string }>(`${apiPath}`, { is_available: false, domain: "" });

  return {
    ...state,
    isStarted,
    join: (kind?: "round" | "deliberations") => state.data.is_available ? setIsStarted(kind || "round") : null,
    leave: () => setIsStarted(null),
  }
}


export interface CourtRoomTokens {
  token_round?: string;
  token_deliberations?: string;
  room_name_round?: string;
  room_display_name_round?: string;
  room_name_deliberations?: string;
  room_display_name_deliberations?: string;
}


export const useCourtRoom = (stage: string | undefined, roundId: string | undefined, dontJoin?: boolean) => {
  const apiPath = `/api/rounds-info/participant/${stage}/${roundId}/court-room`;
  const data = useLoadedData<CourtRoomTokens>(`${apiPath}/tokens`, {}, !dontJoin && !!stage && !!roundId);

  const [isClosed, setIsClosed] = useState<boolean>(false);


  return {
    ...data,

    isClosed,
    close: () => setIsClosed(true),
    rejoin: () => setIsClosed(false),
  }
}

export type CourtRoomJoinData = ReturnType<typeof useCourtRoom>;


export const useTestCourtRoom = (stage: string | undefined, roundId: string | undefined, dontJoin?: boolean) => {
  const apiPath = `/api/rounds-info/participant/test-round/court-room`;
  const data = useLoadedData<CourtRoomTokens>(`${apiPath}/tokens`, {}, !dontJoin && !!stage && !!roundId);

  const [isClosed, setIsClosed] = useState<boolean>(false);


  return {
    ...data,

    isClosed,
    close: () => setIsClosed(true),
    rejoin: () => setIsClosed(false),
  }
}