import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { ParticipantRoundData } from './useParticipantRoundData';
import { PageHeader } from '../../Common/PageHeader';
import { PagePanel, PagePanelMessage } from '../../Common/PageHarness';
import { Button, Typography, useMediaQuery } from '@mui/material';
import { Buttons, Dialog, FormGrid, LoadingIndicator, UIStub } from '../../../toolympus/components/primitives';
import { BaseBiIconProps } from '../../Common/Icons';
import { BiCheck, BiMessageAltError, BiRightArrowAlt } from 'react-icons/bi';
import { ArbitratorDisplay, ContactsLine, ContactsLineWrapper, StatusChip, StatusChipWaitingIcon } from './ArbitratorDisplay';
import { DocumentsWrapper, EditTimingDialog, TeamInfoDisplay, TimingButtons, TimingDisplay } from './TeamInfoDisplay';
import { ThemeSettings } from '../../../theme';
import { FormControlsForFields, useFormats } from '../../../toolympus/components/schemed';
import { FormattedMessage } from 'react-intl';
import { FieldType } from '../../../toolympus/hooks/useSchema';
import { useSnackbar } from 'notistack';
import { useUser } from '../../../toolympus/userContext/UserContext';
import { RoundPanelMessage, whoAmI } from './RoundPagesComponents';
import { ScorePerformanceDialog } from './ScorePerformanceDialog';
import { RoundParticipantsInfoContext, useRoundParticipantsInfoData } from './RoundParticipantsInfoContext';
import { CourtRoomLobbyInside, TestCourtRoomLobbyInside } from './CourtRoom/CourtRoomLobby';
import { Timer } from './Timer';
import { RoundStatusChangeButton, RoundStatusChip, canProvideTiming, getParticipantRoundStatus } from './RoundStatus';
import { TeamTimingCountdown } from './TeamTimingCountdown';

interface Props {
  data: ParticipantRoundData;
  isBailif?: boolean;
  isArbitrator?: boolean;
  isTeam?: boolean;

  testCourtRoom?: boolean;
}


const VsLine = styled.div`
  width: 1px;
  justify-self: stretch;
  flex: 1 0 auto;
  border-right: 3px solid ${ThemeSettings.colors.tableBorderLight};

  @media ${ThemeSettings.sizes.breakpoints.sm.down} {
    width: auto;
    height: 1px;
    border-right: none;
    border-bottom: 3px solid ${ThemeSettings.colors.tableBorderLight};
  }
`;

const Vs = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: ${ThemeSettings.colors.primary};

  & p {
    font-weight: 600;
    margin: 0.5rem 0;
  }

  @media ${ThemeSettings.sizes.breakpoints.sm.down} {
    flex-flow: row;
    height: auto;
    & p {
      margin: 0 0.5rem;
    }
  }
`;

const TeamsForm = styled(FormGrid)`
  & > :last-child {
    align-items: end;

    & ${TimingDisplay} {
      @media ${ThemeSettings.sizes.breakpoints.sm.up} {
        justify-self: end;
      }
    }

    & ${TimingButtons} {
      justify-content: flex-end;
    }

    & ${DocumentsWrapper} {
      justify-content: flex-end;
    }

    & ${ContactsLineWrapper} {
      justify-content: flex-end;
    }

    & h4 {
      text-align: right;
    }
  }
`;




export const ParticipantRoundPage = (props: Props) => {
  const { round, roundInfo, isLoading } = props.data;
  const { formatDatetimeShort } = useFormats();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useUser();

  const participantsInfo = useRoundParticipantsInfoData(round, roundInfo);

  const {
    team1,
    team2,
    teamsTiming,
    teamsContacts,
    teamsAdvisors,
    teamsMembers,
    teamsDocuments,
  } = participantsInfo;

  
  
  const team1Timing = teamsTiming[team1?._id || ""];
  const team2Timing = teamsTiming[team2?._id || ""];
  
  const arbitrators = [1,2,3].map(position => round?.arbiters ? (round.arbiters[position] || null) : null);
  const arbitratorInfo = round?.info?.arbitrator_info || {};
  const iAm = whoAmI(user, team1, team2, arbitrators, roundInfo.bailiff_id);
  const meName = useMemo(() => {
    switch(iAm) {
      case "team1":
        return `${team1.display_name}A`;
      case "team2":
        return `${team2.display_name}R`;
      case "arbitrator1":
        return `${arbitrators[0]?.display_name}`;
      case "arbitrator2":
        return `${arbitrators[1]?.display_name}`;
      case "arbitrator3":
        return `${arbitrators[2]?.display_name}`;
      case "bailiff":
        return `${roundInfo.bailiff?.first_name} ${roundInfo.bailiff?.last_name}`;
    }
  }, [iAm, team1, team2, arbitrators, roundInfo.bailiff]);
  
  const allArbsCheckedIn = arbitrators.filter(a => !!a).every(a => (props.data.roundInfo?.checkins || {})[a?._id || ""]);
  
  const everyoneCheckedIn = !!team1Timing && !!team2Timing && allArbsCheckedIn;

  const isPhone = useMediaQuery(ThemeSettings.sizes.breakpoints.sm.down);

  const roundStatus = getParticipantRoundStatus({
    arbitrators,
    roundInfo,
    everyoneCheckedIn,
    scoreRound: props.data.scoreRound,
    scoringStatus: props.data.scoringStatus,
    role: props.isTeam ? "team" : props.isArbitrator ? "arbitrator" : props.isBailif ? "bailiff" : "admin",
  });

  return (
    <RoundParticipantsInfoContext.Provider value={participantsInfo}>
      <PageHeader
        title={isLoading
          ? "..."
          : <>{props.data.config.messages.general?.round_title_prefix || ""}{round.title}</>}
        isLoading={isLoading}
        actions={!isPhone
          ? <>
            <Button
              color="primary"
              startIcon={<BiMessageAltError {...BaseBiIconProps} />}
              onClick={() => props.data.helpRequest?.startEditing({ issue: "" })}>
                {props.data.config.messages.general?.report_problem_button || ""}
            </Button>
            <RoundStatusChip status={roundStatus} />
            {!!props.data.changeRoundStatus &&
              <RoundStatusChangeButton roundInfo={roundInfo} changeStatus={props.data.changeRoundStatus} />}
          </>
          : undefined}>
          {roundInfo.bailiff && (
            <ContactsLine
              before={`${round.time ? formatDatetimeShort(round.time) : ""}${round.time ? ", " : ""}Bailiff: ${roundInfo.bailiff.first_name} ${roundInfo.bailiff.last_name}`}
              after={roundInfo.bailiff && !(roundInfo.checkins || {})[roundInfo.bailiff_id || ""] && <StatusChip marginHorizontal status="warning" label="Waiting..." icon={<StatusChipWaitingIcon />} />}
              email={roundInfo.bailiff.email}
              phone={roundInfo.bailiff.phone}
              />
          )}
          {isPhone &&
            <Buttons>
              <Button color="primary" startIcon={<BiMessageAltError {...BaseBiIconProps} />}>we have a problem</Button>
              <UIStub label="round status colored" />
            </Buttons>}
      </PageHeader>

      {props.data.helpRequest?.isHelpRequested &&
        <PagePanelMessage invert>
          <RoundPanelMessage
            title={props.data.config.messages.general?.report_problem_waiting || ""}
            />
        </PagePanelMessage>}

      {props.isBailif && props.data.checkin?.isRequireCheckIn &&
        <PagePanelMessage
          invert
          actions={(
            <Button
              style={{ color: ThemeSettings.colors.background }}
              startIcon={props.data.checkin?.isLoading ? <LoadingIndicator sizeVariant="s" color="inherit" /> : <BiRightArrowAlt {...BaseBiIconProps} />}
              onClick={() => props.data.checkin?.checkIn.run()}>
                {props.data.config.messages.bailiff?.check_in_button || ""}
            </Button>
            )}>
          <RoundPanelMessage
            title={props.data.config.messages.bailiff?.check_in_panel_title || ""}
            message={props.data.config.messages.bailiff?.check_in_panel_message || ""}
            />
        </PagePanelMessage>}
      
      {props.isBailif && !everyoneCheckedIn &&
        <PagePanelMessage invert>
          <RoundPanelMessage
            title={props.data.config.messages.bailiff?.ensure_others_checked_in_title}
            message={props.data.config.messages.bailiff?.ensure_others_checked_in_message}
            />
        </PagePanelMessage>}

      {props.isBailif && (props.data.config.messages.bailiff?.guidance_title || props.data.config.messages.bailiff?.guidance_message) &&
        <PagePanelMessage>
          <RoundPanelMessage
            title={props.data.config.messages.bailiff?.guidance_title}
            message={props.data.config.messages.bailiff?.guidance_message}
            />
        </PagePanelMessage>}

      {props.isTeam && props.data.teamsTiming?.isNeedToProvideTiming &&
        <PagePanelMessage invert onClick={() => props.data.teamsTiming?.startEditingX && props.data.teamsTiming.startEditingX()}>
          <RoundPanelMessage
            title={props.data.config.messages.team.timing_provide_panel_title}
            />
        </PagePanelMessage>}

      {props.isArbitrator && props.data.checkin?.isRequireCheckIn &&
        <PagePanelMessage
          invert
          actions={(
            <Button
              style={{ color: ThemeSettings.colors.background }}
              startIcon={props.data.checkin?.isLoading ? <LoadingIndicator sizeVariant="s" color="inherit" /> : <BiRightArrowAlt {...BaseBiIconProps} />}
              onClick={() => props.data.checkin?.checkIn.run()}>
                {props.data.config.messages.arbitrator?.check_in_button || ""}
            </Button>
            )}>
          
          <RoundPanelMessage
            title={props.data.config.messages.arbitrator?.check_in_panel_title || ""}
            message={props.data.config.messages.arbitrator?.check_in_panel_message || ""}
            />
        </PagePanelMessage>}


      {props.isArbitrator && props.data.checkin?.isCheckedIn &&
        <PagePanelMessage
          invert
          actions={(
            <BiCheck {...BaseBiIconProps} />
            )}>
          
          <RoundPanelMessage
            title={props.data.config.messages.arbitrator?.ready_panel_title || ""}
            message={props.data.config.messages.arbitrator?.ready_panel_message || ""}
            />
        </PagePanelMessage>}


      <PagePanel>
        <TeamsForm gap="dense" columns="1fr max-content 1fr" noMargin suppressContentColumnsOnSm>
          <FormGrid columns="1fr" noMargin>
            <Typography variant="h4">Team {team1?.display_name || "..."} (Applicant{iAm === "team1" ? ", you" : ""})</Typography>
            <TeamInfoDisplay
              team={team1}
              iAm={iAm}
              position={1}
              contact={teamsContacts[1]}
              members={teamsMembers[1]}
              advisors={teamsAdvisors[1]}
              documents={teamsDocuments[1]}
              isContactAvailable={props.isBailif || props.isArbitrator}
              isScoringAvailable={props.isArbitrator}
              scoreRound={props.data.scoreRound}
              downloadMemorial={props.data.downloadMemorial}
              messages={props.data.config.messages}
              timing={team1Timing}
              isTimingHidden={!team2Timing && iAm === "team2"}
              startEditTiming={canProvideTiming(props.data.roundInfo.round_status) ? props.data.teamsTiming?.startEditingX : undefined}
              startEditTimingBailiff={props.data.teamsTimingBailiffEdit ? props.data.teamsTimingBailiffEdit.startEditingX : undefined}
              />
          </FormGrid>

          <Vs>
            <VsLine />
            <Typography>vs</Typography>
            <VsLine />
          </Vs>
          
          <FormGrid columns="1fr" noMargin>
            <Typography variant="h4">Team {team2?.display_name || "..."} (Respondent{iAm === "team2" ? ", you" : ""})</Typography>
            <TeamInfoDisplay
              team={team2}
              position={2}
              iAm={iAm}
              contact={teamsContacts[2]}
              members={teamsMembers[2]}
              advisors={teamsAdvisors[2]}
              documents={teamsDocuments[2]}
              isContactAvailable={props.isBailif || props.isArbitrator}
              isScoringAvailable={props.isArbitrator}
              scoreRound={props.data.scoreRound}
              downloadMemorial={props.data.downloadMemorial}
              messages={props.data.config.messages}
              timing={team2Timing}
              isTimingHidden={!team1Timing && iAm === "team1"}
              startEditTiming={canProvideTiming(props.data.roundInfo.round_status) ? props.data.teamsTiming?.startEditingX : undefined}
              startEditTimingBailiff={props.data.teamsTimingBailiffEdit ? props.data.teamsTimingBailiffEdit.startEditingX : undefined}
              />
          </FormGrid>
        </TeamsForm>

      </PagePanel>

      <PagePanel>
        <Typography variant="h4">Judges</Typography>
        <FormGrid columns="1fr 1fr 1fr">
          {arbitrators.map((a,idx) => (
            <ArbitratorDisplay
              key={a?._id || idx}
              arbitrator={a}
              extraInfo={arbitratorInfo[a?._id || ""]}
              position={idx+1}
              isContactAvailable={props.isBailif || props.isArbitrator}
              isCheckedIn={(props.data.roundInfo?.checkins || {})[a?._id || ""]}
              checkin={props.data.checkin?.checkIn?.run}
              isCheckinLoading={props.data.checkin?.isLoading}
              scoringStatus={everyoneCheckedIn ? props.data.scoringStatus : undefined}
              />))}
        </FormGrid>
      </PagePanel>

      <PagePanel>
        <FormGrid noMargin columns="1fr 1fr 1fr" forceEvenColumns>
          {!props.data.timer.isStopped && props.data.timer.team === 1 && !!team1Timing
            ? <TeamTimingCountdown remainingTime={props.data.timer.remainingTime} timerKind={props.data.timer.timerKind} timing={team1Timing} />
            : <div />}
          
          <Timer data={props.data.timer} isControlsAvailable={!props.isTeam} />
          
          {!props.data.timer.isStopped && props.data.timer.team === 2 && !!team2Timing
            ? <TeamTimingCountdown remainingTime={props.data.timer.remainingTime} timerKind={props.data.timer.timerKind} timing={team2Timing} align="right" />
            : <div />}
        </FormGrid>
      </PagePanel>


      {!props.testCourtRoom &&
        <CourtRoomLobbyInside
          stage={props.data.roundInfo?.stage}
          roundId={props.data.roundInfo?._id}
          isDeliberationsAvailable={props.isArbitrator || props.isBailif}
          messages={props.data.config.messages.general}
          userName={meName}
          />}

      {!!props.testCourtRoom &&
        <TestCourtRoomLobbyInside
          stage={props.data.roundInfo?.stage}
          roundId={props.data.roundInfo?._id}
          isDeliberationsAvailable={props.isArbitrator || props.isBailif}
          messages={props.data.config.messages.general}
          userName={meName}
          />}


      <Dialog
        isOpen={!!props.data.helpRequest?.isEditing}
        close={() => props.data.helpRequest?.cancel()}
        noFullscreen
        maxWidth="sm"
        fullWidth
        dialogTitle={props.data.config.messages.general.report_problem_button}
        actions={<>
          <Button onClick={() => props.data.helpRequest?.cancel()}><FormattedMessage id="common.cancel" /></Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => props.data.helpRequest?.save().then(x => { enqueueSnackbar(props.data.config.messages.general?.report_problem_success, { variant: "success", autoHideDuration: 3000 }); return x; })}>
            <FormattedMessage id="common.submit" />
          </Button>
        </>}>
        {props.data.helpRequest?.item && <FormGrid columns="1fr" noMargin>
          <FormControlsForFields
            data={props.data.helpRequest?.item}
            schema={{
              issue: { type: FieldType.textlong, label: "Please describe your issue"}
            }}
            fields={[
              ["issue", { autoRows: true, controlProps: { autoFocus: true } }],
            ]}
            onChange={(o,c) => props.data.helpRequest?.update(c)}
            />
        </FormGrid>}
      </Dialog>

      {props.data.teamsTiming &&
        <EditTimingDialog
          messages={props.data.config.messages}
          data={props.data.teamsTiming}
          />}

      {props.data.teamsTimingBailiffEdit &&
        <EditTimingDialog
          messages={props.data.config.messages}
          data={props.data.teamsTimingBailiffEdit}
          />}

      {props.data.scoreRound &&
        <ScorePerformanceDialog scoreRound={props.data.scoreRound} />}
    </RoundParticipantsInfoContext.Provider>
  );
}
